import Vue from "vue";
import axios from "axios";
import vuex, { Store } from "vuex";
Vue.use(vuex);
export default {
  state: {
    allCategory: [],
    categoryDetail: null,
  },
  actions: {
    async AddCategory({ commit }, payload) {
      return await axios
        .post(`${process.env.VUE_APP_API_URL}Category/AddCategory`, payload)
        .then((response) => {
          commit("SET_CATEGORY", response.data);
          return response.data;
        });
    },
    async EditCategory({ commit }, payload) {
      return await axios
        .put(`${process.env.VUE_APP_API_URL}Category/EditCategory`, payload)
        .then((response) => {
          commit("SET_CATEGORY", response.data);
          return response.data;
        });
    },
    async GetAllCategory({ commit }, payload) {
      return await axios
        .get(`${process.env.VUE_APP_API_URL}Category/GetAllCategory`)
        .then((response) => {
          commit("SET_ALLCATEGORY", response.data);
          return response.data;
        });
    },
    async GetScreeningCategories({ commit }, payload) {
      return await axios
        .get(`${process.env.VUE_APP_API_URL}Category/GetScreeningCategories`)
        .then((response) => {
          return response.data;
        });
    },
    async GetSurveyCategories({ commit }, payload) {
      return await axios
        .get(`${process.env.VUE_APP_API_URL}Category/GetSurveyCategories`)
        .then((response) => {
          return response.data;
        });
    },
    async DeleteCategory({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}Category/DeleteCategory?categoryId=${payload}`
        )
        .then((response) => {
          return response.data;
        });
    },
  },
  mutations: {
    SET_CATEGORY(state, payload) {
      state.categoryDetail = payload.data;
    },
    SET_ALLCATEGORY(state, payload) {
      state.allCategory = payload.data;
    },
  },
  getters: {
    GetAllCategory(state) {
      return state.allCategory;
    },
    GetCategoryDetail(state) {
      return state.categoryDetail;
    },
  },
};
