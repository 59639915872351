import Vue from "vue";
import axios from "axios";
import vuex from "vuex";
Vue.use(vuex);
export default {
  state: {
    allappointment: [],
  },
  getters: {
    GetAppointments(state) {
      return state.allappointment;
    },
  },
  actions: {
    async GetAllAppointment({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Appointment/GetAppointments`,
          header
        )
        .then((res) => {
          commit("SetAllAppointment", res.data);
          return res.data;
        });
    },
  },
  mutations: {
    SetAllAppointment(state, payload) {
      state.allappointment = payload.data;
    },
  },
};
