/* eslint-disable */

import Vue from "vue";
import axios from "axios";
import vuex, { Store } from "vuex";
Vue.use(vuex);
export default {
  state: {
    submittedIntakeFormUsersList: null,
    submittedIntakeByUsersList: null,
    submittedIntakeForm: null,
  },
  actions: {
    async getSubmittedIntakeFormUsers({ commit }) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}IntakeForm/GetSubmittedIntakeFormUsers`
        )
        .then((response) => {
          commit("SetSubmittedIntakeFormUsers", response.data);
          return response.data;
        });
    },
    async getSubmittedIntakeformByUserId({ commit }, userId) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}IntakeForm/GetSubmittedIntakeformByUserId?userId=${userId}`
        )
        .then((response) => {
          commit("SetSubmittedIntakeByUsersList", response.data);
          return response.data;
        });
    },

    async GetSubmittedIntakeformRenderHTMLByUserIdAndSubmissionId(
      { commit },
      payload
    ) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}IntakeForm/GetSubmittedIntakeformRenderHTMLByUserIdAndSubmissionId?userId=${payload.userId}&submissionId=${payload.submissionId}`
        )
        .then((response) => {
          commit("SetsubmittedIntakeForm", response.data);
          return response.data;
        });
    },
  },
  mutations: {
    SetSubmittedIntakeFormUsers(state, payload) {
      state.submittedIntakeFormUsersList = payload.data;
    },
    SetSubmittedIntakeByUsersList(state, payload) {
      state.submittedIntakeByUsersList = payload.data;
    },
    SetsubmittedIntakeForm(state, payload) {
      state.submittedIntakeForm = payload.data;
    },
  },
  getters: {
    getSubmittedIntakeFormUsers(state) {
      return state.submittedIntakeFormUsersList;
    },
    getSubmittedIntakeByUsersList(state) {
      return state.submittedIntakeByUsersList;
    },
    getsubmittedIntakeForm(state) {
      return state.submittedIntakeForm;
    },
  },
};
