import Vue from "vue";
import axios from "axios";
import vuex, { Store } from "vuex";
Vue.use(vuex);
export default {
  state: {
    allSurvey: [],
    surveyInfo: null,
    allScoreChart: [],
  },
  actions: {
    async AddSurvey({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Survey/CreateSurvey`,
          payload,
          header
        )
        .then((response) => {
          commit("SET_SURVEY", response.data);
          return response.data;
        });
    },
    async CreateScoreChart({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Survey/CreateScoreChart`,
          payload,
          header
        )
        .then((response) => {
          commit("SET_ALLSCORECHART", response.data);
          return response.data;
        });
    },
    async GetAllSurvey({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(`${process.env.VUE_APP_API_URL}Survey/GetAllSurvey`, header)
        .then((response) => {
          commit("SET_ALLSURVEY", response.data);
          return response.data;
        });
    },
    async GetScoreChartBySurveyId({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Survey/GetScoreChartBySurveyId?surveyId=${payload}`,
          header
        )
        .then((response) => {
          commit("SET_ALLSCORECHART", response.data);
          return response.data;
        });
    },
    async GetSurveyById({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Survey/GetSurveyById/${payload}`,
          header
        )
        .then((response) => {
          commit("SET_SURVEY", response.data);
          return response.data;
        });
    },
    async DeleteSurvey({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}Survey/DeleteSurvey?surveyId=${payload}`,
          header
        )
        .then((response) => {
          commit("SET_SURVEY", response.data);
          return response.data;
        });
    },
    async DeleteScoreChartById({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}Survey/DeleteScoreChartById?scoreChartId=${payload}`,
          header
        )
        .then((response) => {
          commit("SET_SURVEY", response.data);
          return response.data;
        });
    },
    async EditSurvey({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .put(
          `${process.env.VUE_APP_API_URL}Survey/EditSurvey`,
          payload,
          header
        )
        .then((response) => {
          return response.data;
        });
    },
  },
  mutations: {
    SET_ALLSURVEY(state, payload) {
      state.allSurvey = payload.data;
    },
    SET_SURVEY(state, payload) {
      state.surveyInfo = payload.data;
    },
    SET_ALLSCORECHART(state, payload) {
      state.allScoreChart = payload.data;
    },
  },
  getters: {
    surveyList(state) {
      return state.allSurvey;
    },
    surveyInfo(state) {
      return state.surveyInfo;
    },
    getAllScoreChart(state) {
      return state.allScoreChart;
    },
  },
};
