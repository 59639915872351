/* eslint-disable */

import Vue from "vue";
import axios from "axios";
import vuex, { Store } from "vuex";
Vue.use(vuex);
export default {
  state: {
    allUser: [],
    userInfo: null,
  },
  actions: {
    async GetAllUser({ commit }, payload) {
      return await axios
        .get(`${process.env.VUE_APP_API_URL}User/GetAllUsers`)
        .then((response) => {
          commit("GET_USERS", response.data);
          return response.data;
        });
    },
    async DeleteUser({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}User/DeleteUser?userId=${payload}`,
          header
        )
        .then((response) => {
          return response.data;
        });
    },
    async GetUserDetail({ commit }, payload) {
      return await axios
        .get(`${process.env.VUE_APP_API_URL}User/GetUserById/${payload}`)
        .then((response) => {
          commit("GET_USERINFO", response.data);
          return response.data;
        });
    },
    async downloadUserReports({ commit }) {
      return await axios
        .get(`${process.env.VUE_APP_API_URL}Report/DownloadUsersReport`)
        .then((response) => {
          return response.data;
        });
    },
  },
  mutations: {
    GET_USERS(state, payload) {
      state.allUser = payload.data;
    },
    GET_USERINFO(state, payload) {
      state.userInfo = payload.data;
    },
  },
  getters: {
    getUsers(state) {
      return state.allUser;
    },
    getUserDetail(state) {
      return state.userInfo;
    },
  },
};
