import Vue from "vue";
import axios from "axios";
import vuex,{Store} from "vuex";
Vue.use(vuex);
export default{
    state:{
        allSubCategory:[],
        allSubCategorybyid:[],
        SubcategoryDetail:null
    },
    actions:{
        async AddSubCategory({commit}, payload){
            return await axios
                   .post(`${process.env.VUE_APP_API_URL}Category/AddSubCategory`,payload)
                   .then((response)=>{
                    commit("SET_SUBCATEGORY",response.data);
                    return response.data;
                   })
        },
        async EditSubCategory({commit}, payload){
            return await axios
                   .put(`${process.env.VUE_APP_API_URL}Category/EditSubCategory`,payload)
                   .then((response)=>{
                    commit("SET_SUBCATEGORY",response.data);
                    return response.data;
                   })
        },
        async GetSubCategoriesByCategoryId({commit}, payload){
            return await axios
                   .get(`${process.env.VUE_APP_API_URL}Category/GetSubCategoriesByCategoryId?categoryId=${payload}`)
                   .then((response)=>{
                    commit("SET_ALLSUBCATEGORYBYID",response.data);
                    return response.data;
                   })
        },
        async GetSurveySubCategories({commit}, payload){
            return await axios
                   .get(`${process.env.VUE_APP_API_URL}Category/GetSurveySubCategories`)
                   .then((response)=>{
                    return response.data;
                   })
        },
        async GetSubCategories({commit}, payload){
            return await axios
                   .get(`${process.env.VUE_APP_API_URL}Category/GetSubCategories`)
                   .then((response)=>{
                    commit("SET_ALLSUBCATEGORY",response.data);
                    return response.data;
                   })
        },
        async DeleteSubCategory({commit}, payload){
            return await axios
                   .delete(`${process.env.VUE_APP_API_URL}Category/DeleteSubCategory?subCategoryId=${payload}`)
                   .then((response)=>{
                    return response.data;
                   })
        },
    },
    mutations:{
        SET_SUBCATEGORY(state, payload){
            state.categoryDetail = payload.data;
        },
        SET_ALLSUBCATEGORYBYID(state, payload){
            state.allSubCategorybyid = payload.data;
        },
        SET_ALLSUBCATEGORY(state, payload){
            state.allSubCategory = payload.data;
        }
    },
    getters:{
        GetAllSubCategory(state){
            return state.allSubCategory;
        },
        GetAllSubCategorybyid(state){
            return state.allSubCategorybyid;
        },
        GetSubCategoryDetail(state){
            return state.SubcategoryDetail;
        }
    },
}