import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Swal from 'sweetalert2';
import "../src/assets/style/main.scss"
import { VueEditor } from "vue2-editor";


Vue.component("VueEditor", VueEditor);
Vue.config.productionTip = false
Vue.prototype.$swal = function(message,type) {
  Swal.fire({
    position: "center",
    icon: type,
    title: message,
    showConfirmButton: false,
    timer: 3000
  });
};
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
