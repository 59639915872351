import Vue from "vue";
import axios from "axios";
import vuex,{Store} from "vuex";
Vue.use(vuex);
export default{
    state:{
        allRole:[],
        roleData:null,
    },
    actions:{
        async AddUserRole({commit}, payload){
            var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
            const header = {
                headers:{
                    Authorization : `Bearer ${bearerToken}`
                }
            };
            return await axios
                   .post(`${process.env.VUE_APP_API_URL}User/AddNewUserRoles`,payload,header)
                   .then((response)=>{
                    commit("SET_NEWROLE",response.data);
                    return response.data;
                   })
        },
        async GetAllRole({commit}, payload){
            var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
            const header = {
                headers:{
                    Authorization : `Bearer ${bearerToken}`
                }
            };
            return await axios
                   .get(`${process.env.VUE_APP_API_URL}User/GetAllUserRoles`)
                   .then((response)=>{
                    commit("SET_ALLROLE",response.data);
                    return response.data;
                   })
        },
        async DeleteRole({commit}, payload){
            var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
            const header = {
                headers:{
                    Authorization : `Bearer ${bearerToken}`
                }
            };
            return await axios
                   .delete(`${process.env.VUE_APP_API_URL}User/DeleteRole?roleId=${payload}`,header)
                   .then((response)=>{
                    return response.data;
                   })
        },
    },
    mutations:{
        SET_NEWROLE(state, payload){
            state.roleData = payload.data;
        },
        SET_ALLROLE(state,payload){
            state.allRole = payload.data;
        }
    },
    getters:{
        getRole(state){
            return state.roleData;
        },
        getAllRole(state){
            return state.allRole
        }
    },
}