import Vue from "vue";
import axios from "axios";
import vuex, { Store } from "vuex";
Vue.use(vuex);
export default {
  state: {
    LoggedinAdmin: null,
    isLoggedIn: false,
    adminInfo: null,
    loginUsers: [],
  },
  actions: {
    async Login({ commit }, payload) {
      return await axios
        .post(`${process.env.VUE_APP_API_URL}User/Login`, payload)
        .then((response) => {
          commit("SET_ADMIN", response.data);
          return response.data;
        });
    },
    async GetLoginUsers({ commit }, payload) {
      return await axios
        .get(`${process.env.VUE_APP_API_URL}User/GetLoginUsers`)
        .then((response) => {
          commit("SET_LOGINUSERS", response.data);
          return response.data;
        });
    },
  },
  mutations: {
    SET_ADMIN(state, payload) {
      localStorage.setItem("isLoggedIn_AD", JSON.stringify(payload.data));
      state.LoggedinAdmin = payload.data;
      state.isLoggedIn = true;
    },
    SET_LOGINUSERS(state, payload) {
      state.loginUsers = payload.data;
    },
  },
  getters: {
    IsLoggedIn(state) {
      if (JSON.parse(localStorage.getItem("isLoggedIn_AD")) == null) {
        state.isLoggedIn = false;
        return state.isLoggedIn;
      }
      state.adminInfo = JSON.parse(localStorage.getItem("isLoggedIn_AD"));
      state.isLoggedIn = true;
      return state.isLoggedIn;
    },
    AdminInfo(state) {
      if (JSON.parse(localStorage.getItem("isLoggedIn_AD")) == null) {
        return false;
      }
      state.adminInfo = JSON.parse(localStorage.getItem("isLoggedIn_AD"));
      return state.adminInfo;
    },
    LoginUsers(state) {
      return state.loginUsers;
    },
  },
};
