import Vue from "vue";
import axios from "axios";
import vuex, { Store } from "vuex";
Vue.use(vuex);
export default {
  state: {
    allContent: [],
    contentData: null,
    welcomeContent: null,
  },
  actions: {
    async GetAllContent({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(`${process.env.VUE_APP_API_URL}Content/GetAllContent`, header)
        .then((response) => {
          commit("SET_ALLCONTENT", response.data);
          return response.data;
        });
    },
    async GetBlogContent({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(`${process.env.VUE_APP_API_URL}Content/GetBlogContent`, header)
        .then((response) => {
          commit("SET_ALLCONTENT", response.data);
          return response.data;
        });
    },
    async AddContent({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Content/AddContent`,
          payload,
          header
        )
        .then((response) => {
          commit("SET_CONTENT", response.data);
          return response.data;
        });
    },
    async EditContent({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .put(
          `${process.env.VUE_APP_API_URL}Content/EditContent`,
          payload,
          header
        )
        .then((response) => {
          commit("SET_CONTENT", response.data);
          return response.data;
        });
    },
    async AddBlogContent({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Content/AddBlogContent`,
          payload,
          header
        )
        .then((response) => {
          commit("SET_CONTENT", response.data);
          return response.data;
        });
    },
    async GetContentById({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Content/GetContentById`,
          payload,
          header
        )
        .then((response) => {
          commit("SET_CONTENT", response.data);
          return response.data;
        });
    },
    async GetFilteredContents({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Content/GetFilteredContents?searchString=${payload}`
        )
        .then((response) => {
          commit("SET_ALLCONTENT", response.data);
          return response.data;
        });
    },
    async DeleteContent({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      console.log(payload);
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}Content/DeleteContent?contentId=${payload}`,
          {
            headers: header.headers,
          }
        )
        .then((response) => {
          return response.data;
        });
    },
    async GetWelcomeContent({ commit }, payload) {
      var bearerToken = JSON.parse(localStorage.getItem("isLoggedIn_AD")).token;
      const header = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };
      let url = `${process.env.VUE_APP_API_URL}User/UpdateDashboard`;
      return await axios.post(url, payload, header).then((response) => {
        commit("setWelcomeContent", response.data);
        return response.data;
      });
    },
  },
  mutations: {
    SET_ALLCONTENT(state, payload) {
      state.allContent = payload.data;
    },
    SET_CONTENT(state, payload) {
      state.contentData = payload.data;
    },
    setWelcomeContent(state, payload) {
      state.welcomeContent = payload;
    },
  },
  getters: {
    getAllContents(state) {
      return state.allContent;
    },
    getContent(state) {
      return state.contentData;
    },
    GetWelcomeContent(state) {
      return state.welcomeContent;
    },
  },
};
