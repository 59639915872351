import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/dashboardview",
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("../views/PrivacyPolicy/privacyPolicy.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import("../views/PrivacyPolicy/contactus.vue"),
  },
  {
    path: "/deleteaccount",
    name: "deleteaccount",
    component: () => import("../views/PrivacyPolicy/deleteAccount.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("../views/Main/DashboardLayout.vue"),
    redirect: "/user",
    children: [
      {
        path: "/dashboardview",
        name: "dashboard",
        component: () => import("../views/Dashboard/dashboard.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("../views/users/users.vue"),
      },
      {
        path: "/subcategory",
        component: () => import("../views/SubCategory/subCategory.vue"),
      },
      {
        path: "/category",
        component: () => import("../views/category/category.vue"),
      },
      {
        path: "/survey",
        component: () => import("../views/Surveys/surveys.vue"),
      },
      {
        path: "/survey/addnewsuvrey",
        component: () => import("../views/Surveys/addnewsurvey.vue"),
      },
      {
        path: "/survey/editsurvey",
        component: () => import("../views/Surveys/editscreening.vue"),
      },
      {
        path: "/content",
        component: () => import("../views/Content/content.vue"),
      },
      {
        path: "/appointmentrequest",
        component: () =>
          import("../views/AppoitmentRequest/AppointmentRequest.vue"),
      },
      {
        path:"/welcome",
        component: () => import("../views/Welcome")
      }
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (store.getters["IsLoggedIn"]) {
    if (to.name === "login") {
      next({ name: "dashboard" });
    } else {
      next();
    }
  } else {
    if (
      to.name == "login" ||
      to.name == "privacypolicy" ||
      to.name == "contactus" ||
      to.name == "deleteaccount"
    ) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
