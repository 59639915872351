<template>
  <v-app>
    <v-main>
      <!-- <loadercomponent ></loadercomponent> -->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
//import Loadercomponent from './components/loader/loadercomponent.vue';
export default {
  name: "App",

  data: () => ({
   
    //Loadercomponent
  }),
};
</script>
