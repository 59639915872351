import Vue from "vue";
import Vuex from "vuex";
import Auth from "./Auth";
import User from "./User";
import Category from "./Category";
import Content from "./Content";
import Role from "./Role";
import Survey from "./Survey";
import SubCategory from "./SubCategory";
import Appointment from "./Appointment";
import UserIntakeForms from "./UserIntakeForms";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    User,
    Category,
    Content,
    Role,
    Survey,
    SubCategory,
    Appointment,
    UserIntakeForms,
    
  },
});
